import axios from 'axios'

export const searchUrl = '/.netlify/functions/search'

export const search = query =>
  axios
    .get(searchUrl, {
      params: {
        q: query,
      },
    })
    .then(({ data }) => data)

export default {
  search,
}
