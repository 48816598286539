import React from 'react'
import PropTypes from 'prop-types'

import Layout from 'components/Layout/Layout'
import SearchContainer from 'components/SearchContainer/SearchContainer'
import PopularTopics from 'components/PopularTopics/PopularTopics'

import gridStyles from 'styles/grid.module.scss'

const SearchPage = ({ location }) => (
  <Layout location={location}>
    <div className={gridStyles.lbhContainer}>
      <h1>Search</h1>
      <SearchContainer location={location} />
      <PopularTopics />
    </div>
  </Layout>
)

SearchPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default SearchPage
