import React from 'react'
import PropTypes from 'prop-types'

import styles from './SearchResults.module.scss'

const SearchResults = ({ results }) => (
  <ul className={styles.list}>
    {results.map(({ searchDisplayData, post_title, pathname }) => (
      <li className={styles.listItem} key={post_title}>
        <a className={styles.title} href={pathname}>
          {post_title}
        </a>
        <div className={styles.text}>{searchDisplayData}...</div>
      </li>
    ))}
  </ul>
)

SearchResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      searchDisplayData: PropTypes.string.isRequired,
      post_title: PropTypes.string.isRequired,
      pathname: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default SearchResults
