import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaTimes } from 'react-icons/fa'
import cx from 'classnames'

import SearchApi from 'utils/searchAPI'
import SearchResults from 'components/SearchResults/SearchResults'

import styles from './SearchContainer.module.scss'

const SearchContainer = ({ location }) => {
  const [results, setResults] = useState()
  const [query, setQuery] = useState()
  const [prevQuery, setPrevQuery] = useState()
  const [loading, setLoading] = useState()
  useEffect(() => {
    const hash = decodeURI(location.hash && location.hash.substring(1))
    setQuery(hash)
    handleSearch(hash)
  }, [location.hash])
  const handleSearch = async query => {
    setPrevQuery(query)
    setLoading(query)
    setResults()
    const results = query.length > 0 && (await SearchApi.search(query))
    results && setResults(results)
  }
  const onSubmit = e => {
    e.preventDefault()
    if (query === prevQuery) {
      return null
    }
    // gatsby location is not setting the hash
    window.location.hash = query
  }
  const onClearQuery = () => {
    setQuery('')
    setResults()
    setLoading()
  }
  return (
    <div>
      <form className={styles.form} onSubmit={onSubmit}>
        <input
          className={styles.input}
          title="Search term must contain at least 3 characters"
          id="Search"
          type="text"
          value={query}
          onChange={({ target }) => setQuery(target.value)}
          placeholder="Enter your search here"
          aria-label="Enter your search here"
        />
        {results && (
          <button
            type="reset"
            className={cx(styles.button, styles.clear)}
            onClick={onClearQuery}
            aria-label="reset search"
          >
            <FaTimes />
          </button>
        )}
        <button
          className={cx(styles.button, styles.submit)}
          type="submit"
          disabled={!query || query.length < 3}
        >
          Search
        </button>
      </form>
      {loading && (
        <div
          className={styles.resultsLabel}
          role="status"
          aria-label="Search results status"
        >
          Searching on &quot;{loading}&quot;{' '}
          {results ? `found ${results.length} results.` : '...'}
        </div>
      )}
      {results && results.length > 0 && <SearchResults results={results} />}
    </div>
  )
}

SearchContainer.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired,
}

export default SearchContainer
