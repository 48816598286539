import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import styles from './PopularTopics.module.scss'

const query = graphql`
  query PopularTopics {
    wordpressAcfOptions {
      options {
        search_page_links {
          text
          url
        }
      }
    }
  }
`

export const PopularTopics = ({ wordpressAcfOptions }) => (
  <nav>
    <h3>Popular searches</h3>
    <ul className={styles.list}>
      {wordpressAcfOptions.options.search_page_links.map(link => (
        <li key={link.text}>
          <a href={link.url}>{link.text}</a>
        </li>
      ))}
    </ul>
  </nav>
)

PopularTopics.propTypes = {
  wordpressAcfOptions: PropTypes.shape({
    options: PropTypes.shape({
      search_page_links: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

const PopularTopicsWithStaticQuery = () => (
  <StaticQuery query={query} render={PopularTopics} />
)

export default PopularTopicsWithStaticQuery
